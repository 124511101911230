import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormikProvider } from 'formik';
import { useReactiveVar } from '@apollo/client';
import Collapse from '@mui/material/Collapse';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { LoadingButton } from '@mui/lab';
import PasswordRecoveryLink from '../links/PasswordRecoveryLink';
import AceptaLoginButton from '../buttons/AceptaLoginButton';
import EmailField from '../fields/EmailField';
import PasswordField from '../fields/PasswordField';
import { authEmailVar } from '../../graphql/reactive-variables';
import useLoginForm from '../../hooks/useLoginForm';

const STEPS = [
  'Ingresa tu email para ingresar a tu cuenta',
  'Ingresa tu contraseña',
];

const LoginForm = ({ redirectTo = '/app/home', landingTag = null }) => {
  const authEmail = useReactiveVar(authEmailVar);
  const formik = useLoginForm(redirectTo, landingTag);
  const { isSubmitting, isValid } = formik;

  const currentStep = useMemo(() => (authEmail ? 1 : 0), [authEmail]);

  return (
    <>
      <MobileStepper
        variant="dots"
        position="static"
        steps={2}
        activeStep={authEmail ? 1 : 0}
        sx={{
          justifyContent: 'center',
        }}
      />
      <Typography align="center" mt="0px !important" mb="4px !important">
        {STEPS[currentStep]}
      </Typography>
      <FormikProvider value={formik}>
        <form
          id="sign-in-form"
          onSubmit={formik.handleSubmit}
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <EmailField variant="outlined" disabled={!!authEmail} />
          <Collapse
            in={!!authEmail}
            component={Stack}
            alignItems="center"
            width="100%"
            unmountOnExit
          >
            <PasswordField variant="outlined" />
            <PasswordRecoveryLink />
          </Collapse>
          <LoadingButton
            id={authEmail ? 'login-button' : 'continue-button'}
            variant="form"
            color="primary"
            size="small"
            type="submit"
            disabled={isSubmitting || !isValid}
            loading={isSubmitting}
            sx={{ mb: 1 }}
            fullWidth
          >
            {authEmail ? 'Ingresar' : 'Siguiente'}
          </LoadingButton>
          <AceptaLoginButton />
        </form>
      </FormikProvider>
    </>
  );
};

LoginForm.propTypes = {
  landingTag: PropTypes.string,
  redirectTo: PropTypes.string,
};

LoginForm.defaultProps = {
  landingTag: null,
  redirectTo: '/app/home',
};

export default LoginForm;
