import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useReactiveVar } from '@apollo/client';
import { useBooleanState, useSelectedCompany } from '@fingo/lib/hooks';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { CESSIONS_SUMMARY } from '@fingo/lib/graphql';
import { SummaryCard } from '@fingo/lib/components/cards';
import { CessionInvoicesFilter } from '@fingo/lib/components/filters';
import { formatMoney } from '@fingo/lib/helpers';
import currencyVar from '@fingo/lib/apollo/reactive-variables/money';
import PorftolioDownload from '../PorfolioDownload';

const MexicoCessionsSummary = ({ setCessionInvoices }) => {
  const [portfolioOpen, togglePortfolio] = useBooleanState(false);
  const selectedCompany = useSelectedCompany();
  const currency = useReactiveVar(currencyVar);
  const {
    data: cessionsSummary,
    loading,
    refetch,
  } = useQuery(CESSIONS_SUMMARY, {
    variables: { companyId: selectedCompany?.id, currency },
    skip: !selectedCompany,
  });
  const showSymbol = true;

  useEffect(() => {
    if (currency) {
      refetch({ companyId: selectedCompany?.id, currency });
    }
  }, [currency]);

  return (
    <>
      <Grid
        container
        padding={2}
        alignItems="center"
        justifyContent="space-around"
        sx={{
          bgcolor: 'background.light',
          borderRadius: 2,
          flexWrap: 'wrap',
        }}
        lg={12}
        md={11}
        sm={10}
        xs={8}
        rowSpacing={{ lg: 0, xs: 1, md: 1 }}
      >
        <Grid item xs={4.5} lg={2}>
          <SummaryCard
            title={`${
              cessionsSummary?.cessionsSummary.invoiceCount
            } | ${formatMoney(
              cessionsSummary?.cessionsSummary.amount,
              showSymbol,
            )}`}
            subtitle="Facturas cedidas"
            loading={loading}
          />
        </Grid>
        <Grid item xs={4.5} lg={2}>
          <SummaryCard
            title={`${
              cessionsSummary?.cessionsSummary.debtCount
            } | ${formatMoney(
              cessionsSummary?.cessionsSummary.debtAmount,
              showSymbol,
            )}`}
            subtitle="Facturas en mora"
            loading={loading}
          />
        </Grid>
        <Grid item xs={4.5} lg={2}>
          <CessionInvoicesFilter
            setCessionInvoicesCallback={setCessionInvoices}
          />
        </Grid>
        <Grid item xs={4.5} lg={2}>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            fullWidth
            onClick={togglePortfolio}
            id="toggle-download-portfolio"
          >
            Descargar resumen
          </Button>
        </Grid>
      </Grid>
      <PorftolioDownload
        open={portfolioOpen}
        onClose={togglePortfolio}
        product="FACTORING"
      />
    </>
  );
};

MexicoCessionsSummary.propTypes = {
  setCessionInvoices: PropTypes.func,
};

MexicoCessionsSummary.defaultProps = {
  setCessionInvoices: () => {},
};

export default MexicoCessionsSummary;
